import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/views/home';
import Topic from '@/views/topic';
import Profile from '@/views/profile';
import Project from '@/views/project';
import Project_View from '@/containts/project_view';
import research from '@/views/research';
import event from '@/views/event';
import About from '@/views/about';
import Admin from '@/views/admin';
import Member from '@/views/profile/member';
import Course from '@/views/profile/course';
import EventE from '@/views/event/eventENG';
import SGJ10  from '@/views/event/SGJ10';
import Privacypolicy from '@/views/products/hinannjyoQ/privacypolicy'

Vue.use(Router);

export default new Router({
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/web',
      redirect: '/',
    },
    {
      path: '/topic',
      name: 'topic',
      component: Topic,
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
    },
    {
      path: '/project',
      name: 'project',
      component: Project,
    },
    {
      path: '/project_view?id=:id',
      name: 'project_view',
      props: true,
      component: Project_View,
    },
    {
      path: '/research',
      name: 'research',
      component: research,
    },
    {
      path: '/event',
      name: 'event',
      component: event,
    },
    {
      path: '/about',
      name: 'about',
      component: About,
    },
    {
      path: '/amdtilnshgrlawhgiorhoihgriohgreio890723590ebfAAAAASGHCVJFC8788opkm6',
      name: 'admin',
      component: Admin,
    },
    {
      path: '/profile/member',
      name: 'member',
      component: Member,
    },
    {
      path: '/profile/course',
      name: 'course',
      component: Course,
    },
    {
      path: '/event/eventENG',
      name: 'eventE',
      component: EventE,
    },
    {
      path: '/event/SGJ10',
      name: 'SGJ10',
      component: SGJ10,
    },
    {
      path: '/products/hinannjyoQ/privacypolicy',
      name: 'privacypolicy',
      component: Privacypolicy,
    },
  ],
});
