<template>
    <v-app>
      <v-main class="bg-grey-lighten-4">
        <v-container>
          <v-row justify="center">
            <v-col cols="12" md="10" lg="8">
              <v-card class="mt-4 mb-4">
                <v-card-title class="text-h4 pa-6 text-center">
                  避難所生活Q プライバシーポリシー
                </v-card-title>
                <v-card-subtitle class="text-center pb-0">
                  最終更新日: 2024年12月8日
                </v-card-subtitle>
                <!-- 概要セクション -->
                <v-card-text>
                  <v-alert
                    color="info"
                    variant="outlined"
                    class="mb-4"
                  >
                    本アプリは個人情報を一切収集せず、プライバシーを最大限に尊重して運営されています。
                  </v-alert>
                  <!-- 目次 -->
                  <v-card outlined class="mb-6">
                    <v-card-title class="text-h6">
                      <v-icon start>mdi-format-list-bulleted</v-icon>
                      目次
                    </v-card-title>
                    <v-card-text>
                      <v-list density="compact">
                        <v-list-item
                          v-for="(section, index) in sections"
                          :key="index"
                          :href="'#' + section.id"
                          class="ps-2"
                        >
                          {{ section.title }}
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                  <!-- 概要セクション -->
                  <section id="overview" class="mb-6">
  <h2 class="text-h5 mb-4">概要</h2>
  <div class="pa-4">
    <div class="mb-4">
      <h3 class="text-subtitle-1 font-weight-bold mb-2">アプリ提供者</h3>
      <p class="ml-3">松野研究室に所属する個人を指します</p>
    </div>
    <div class="mb-4">
      <h3 class="text-subtitle-1 font-weight-bold mb-2">取得する情報とその目的</h3>
      <p class="ml-3">本アプリケーションは、ユーザーの個人情報を利用することはありません。アプリケーションの機能は、ユーザーの個人情報に依存せず、個人情報を収集または保存することなく提供されます。</p>
    </div>
    <div class="mb-4">
      <h3 class="text-subtitle-1 font-weight-bold mb-2">外部送信と情報収集モジュール</h3>
      <p class="ml-3">本アプリケーションでは、ユーザーの個人情報を第三者と共有することはありません。また、クッキーやその他の追跡技術を使用していません。</p>
    </div>
  </div>
</section>
<!-- 定義セクション -->
<section id="definitions" class="mb-6">
  <h2 class="text-h5 mb-4">解釈と定義</h2>
  <div class="pa-4">
    <p class="mb-4">次の単語は、以下の条件に従って定義される意味を持ちます。</p>
    <h3 class="text-subtitle-1 font-weight-bold mb-3">定義</h3>
    <p class="mb-4">このプライバシーポリシーの目的で、以下の定義が適用されます：</p>
    <div class="ml-3">
      <div v-for="(def, index) in definitions" :key="index" class="mb-4">
        <h4 class="font-weight-bold mb-1">{{ def.term }}</h4>
        <p>{{ def.description }}</p>
      </div>
    </div>
  </div>
</section>
                  <!-- 個人データセクション -->
                  <section id="personal-data" class="mb-6">
                    <h2 class="text-h5 mb-4">収集する個人データ</h2>
                    <v-alert
                      type="info"
                      variant="outlined"
                      class="mb-4"
                    >
                      本アプリケーションでは、いかなる個人情報も収集いたしません。
                    </v-alert>
                    <p>以下の情報を含む、一切の個人情報を収集することはありません：</p>
                    <v-list density="compact">
                      <v-list-item v-for="(item, index) in personalDataItems" :key="index">
                        <v-list-item-title>
                          <v-icon>mdi-close-circle</v-icon>
                          {{ item }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </section>
                  <!-- その他のセクション -->
                  <section v-for="section in otherSections" :key="section.id" :id="section.id" class="mb-6">
                    <h2 class="text-h5 mb-4">{{ section.title }}</h2>
                    <p>{{ section.content }}</p>
                  </section>
                  <!-- お問い合わせセクション -->
                  <section id="contact" class="mb-6">
                    <h2 class="text-h5 mb-4">お問い合わせ</h2>
                    <v-card variant="outlined">
                      <v-card-text>
                        <p>プライバシーポリシーに関してご質問がある場合は、以下の方法でお問い合わせください：</p>
                        <p class="mb-0">松野研究室メールアドレス：[メアド]</p>
                      </v-card-text>
                    </v-card>
                  </section>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-app>
  </template>
  <script>
  export default {
    data() {
      return {
        sections: [
          { id: 'overview', title: '概要' },
          { id: 'definitions', title: '解釈と定義' },
          { id: 'personal-data', title: '収集する個人データ' },
          { id: 'cookies', title: 'クッキーと追跡技術' },
          { id: 'sharing', title: '第三者との情報の共有' },
          { id: 'protection', title: 'データの保護' },
          { id: 'children', title: '子ども向けサービス' },
          { id: 'changes', title: 'プライバシーポリシーの変更' },
          { id: 'contact', title: 'お問い合わせ' }
        ],
        definitions: [  
            {    term: 'アプリケーション',    description: '「松野研究室」が提供するソフトウェアプログラム「避難所生活Q」を指します。'  },  {    term: '松野研究室',    description: '（本契約で「会社」「私たち」「私たちの」などと呼ばれる）は、本アプリ提供者を指します。'  },  {    term: 'デバイス',    description: 'コンピュータ、携帯電話、デジタルタブレットなど、サービスにアクセスできるデバイスを指します。'  },  {    term: '個人データ',    description: '識別可能な個人に関する情報を指します。'  },  {    term: '利用データ',    description: 'サービスの使用中に自動的に収集されるデータを指します。'  },  {    term: 'あなた',    description: 'サービスにアクセスまたは使用する個人、またはその個人の代理としてサービスにアクセスまたは使用する会社や法人を指します。'  }],
        personalDataItems: [
          '名前',
          'メールアドレス',
          '住所',
          '電話番号',
          '支払い情報',
          'ユーザーの行動データ（使用履歴、位置情報など）'
        ],
        otherSections: [
          {
            id: 'cookies',
            title: 'クッキーと追跡技術',
            content: '本アプリケーションでは、クッキーやその他の追跡技術を使用していません。ユーザーのオンライン行動を追跡することはなく、ユーザーのプライバシーは保護されています。'
          },
          {
            id: 'sharing',
            title: '第三者との情報の共有',
            content: '本アプリケーションでは、ユーザーの個人情報を第三者と共有することはありません。したがって、ユーザーが提供した情報が第三者に送信されることはありません。'
          },
          {
            id: 'protection',
            title: 'データの保護',
            content: '本アプリケーションは、個人情報を収集しないため、特別なセキュリティ対策は必要ありませんが、一般的なセキュリティ対策を講じており、アプリケーションの健全な動作が保たれるように努めています。'
          },
          {
            id: 'children',
            title: '子ども向けサービス',
            content: '本アプリケーションは13歳未満の子供を対象としていません。13歳未満の子供からの個人情報は意図的に収集しません。'
          },
          {
            id: 'changes',
            title: 'プライバシーポリシーの変更',
            content: '本ポリシーは、必要に応じて更新されることがあります。変更がある場合は、アプリケーション内で通知するか、日本大学松野研究室ウェブサイトにて最新のポリシーを公開します。'
          }
        ]
      }
    }
  }
  </script>
  <style scoped>
  .v-list-item-title {
    white-space: normal;
    word-break: break-word;
  }
  .v-list-item-subtitle {
    white-space: normal;
    word-break: break-word;
  }
  </style>